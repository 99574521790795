export const DETAILS = {
    name: " فروشگاه آرمیتا بیوتی",
    shortName: "فروشگاه آرمیتا بیوتی",
    footerName: "فروشگاه آرمیتا بیوتی",
    description: "",
    shortAbout: " فروشگاه آرمیتا بیوتی از سال ۱۳۸۴ در زمینه فروش عطر و ادکلن، آرایشی و\n" +
        "                                                بهداشتی و محصولات تخصصی پوست آغاز به فعالیت کرد. از همان ابتدا تمامی هدف\n" +
        "                                                فروشگاه جلب رضایت مشتری و ارائه خدمات نوین بوده و تا به امروز در کنار\n" +
        "                                                شما مشتریان گرامی و با حمایت دوستانه و صمیمانه شما این اهداف دنبال شده و\n" +
        "                                                زین پس نیز زیر سایه گرم شما ادامه خواهد داشت.\n" +
        "                                                تمامی محصولات در این فروشگاه اورجینال بوده و تحت نظارت نمایندگی های\n" +
        "                                                مربوطه میباشد. با نظرات و پیشنهادات خود ما را در ارائه خدمات حرفه ای تر\n" +
        "                                                یاری نمائید.\n" +
        "                                                با ضمانت فروشگاه آرمیتا بیوتی آسوده خاطر محصولات ما را خریداری نمائید.",
    link: "https://armitabeauty.com",
    mobile: ["02636524295", "02636524283"],
    phone: [],
    email: [],
    postalCode: [],
    address: ["فردیس ، پایین تر از کانال غربی ، بین خیابان بیست و هفتم و خیابان بیست و نهم غربی ، فروشگاه آرمیتا بیوتی"],
    supportDescription: "",
    workingHours: ["شنبه تا پنجشنبه 10 تا 13 و 16:30 تا 21", "جمعه 17 تا 21"],
    mapIframeSrc: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6478.332603635765!2d50.98824539642212!3d35.722128366317314!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3f8d949efd48e8c5%3A0x7755822ae0923f76!2z2YHYsdmI2LTar9in2Ycg2KLYsdin24zYtNuMINio2YfYr9in2LTYqtuMINii2LHZhduM2KrYpw!5e0!3m2!1sfa!2s!4v1707138960273!5m2!1sfa!2s",
    socialMedia: [
        {
            title: 'واتس اپ',
            value: '09105894283',
            name: 'whatsapp',
            link: 'https://api.whatsapp.com/send/?phone=09105894283&text&app_absent=0',
            icon: <i className="lab la-whatsapp"/>,
            isPrimary: true
        },
        {
            title: 'تلگرام',
            value: '09105894283',
            name: 'telegram',
            link: 'https://t.me/09105894283',
            icon: <i className="lab la-telegram"/>,
            isPrimary: true
        },
        {
            title: 'اینستاگرام',
            value: 'armitabeauty',
            name: 'instagram',
            link: "https://instagram.com/armitabeauty",
            icon: <i className="lab la-instagram"/>,
            isPrimary: true
        },
        /*{
                    title: 'ایتا',
                    value: '09357195494',
                    name: 'eitaa',
                    link: 'https://eitaa.com/09357195494',
                    icon: <img src={eitaaIcon} alt='ایتا'/>,
                    isPrimary: false
                }
        {
            title: 'ایمیل',
            value: 'Volvotak@gmail.com',
            name: 'email',
            link: 'mailto:Volvotak@gmail.com',
            icon: <i className="las la-envelope"></i>,
            isPrimary: true
        },
        {
            title: 'تس',
            value: '09133377432',
            name: 'phone',
            link: 'tel:989133377432',
            icon: <PhoneCall/>,
            isPrimary: true
        },
                /*{
                    title: 'آپارات',
                    value: 'Alo_aghsat',
                    name: 'aparat',
                    link: 'https://www.aparat.com/Alo_aghsat',
                    icon: <SiAparat size={25}/>,
                    isPrimary: true
                },
                {
                    title: 'تماس',
                    value: '02636634893',
                    name: 'phone',
                    link: 'tel:+982636634893',
                    icon: <PhoneCall/>,
                    isPrimary: false
                },
                {
                    title: 'سروش پلاس',
                    value: '09331074475',
                    name: 'soroushPlus',
                    link: '',
                    icon: <img src={soroushIcon} alt='سروش'/>,
                    isPrimary: false
                },
                {
                    title: 'سروش پلاس',
                    value: '09332094475',
                    name: 'soroushPlus',
                    link: '',
                    icon: <img src={soroushIcon} alt='سروش'/>,
                    isPrimary: false
                },
                {
                    title: 'سروش پلاس',
                    value: '09330324475',
                    name: 'soroushPlus',
                    link: '',
                    icon: <img src={soroushIcon} alt='سروش'/>,
                    isPrimary: false
                },
                {
                    title: 'ایتا',
                    value: '09331074475',
                    name: 'eitaa',
                    link: '',
                    icon: <img src={eitaaIcon} alt='ایتا'/>,
                    isPrimary: false
                },*/
    ],
    services: [],
    brands: [
        /*{
            name: 'Apple',
            image: <img src={appleImage} alt='apple'/>,
        },*/
    ],
    "enamadLink": "",
    "samandehiLink": "",
    "ZarinpalLink": "https://www.zarinpal.com/webservice/TrustCode"
}
