import React from "react"
import * as Icon from "react-feather"
import {
    CgUnavailable,
    FaBirthdayCake,
    GiDiamondRing,
    MdOutlinePriceChange,
    MdQuestionMark,
    TbBrandAsana,
    TbBrandCampaignmonitor
} from "react-icons/all";

const navigationConfig = [
    // SYSTEM NAVIGATION

    {
        id: "system_dashboard",
        title: "Dashboard",
        type: "item",
        icon: <Icon.Home size={20}/>,
        navLink: '/system/dashboard',
        permissions: ["system"]
    },


    // ADMIN NAVIGATION
    {
        id: "admin_dashboard",
        title: "Dashboard",
        type: "item",
        icon: <Icon.Home size={20}/>,
        navLink: '/admin/dashboard',
        permissions: ["admin"]
    },
    {
        id: "admin_customers",
        title: "باشگاه مشتریان",
        type: "item",
        icon: <Icon.User size={20}/>,
        permissions: ["admin"],
        navLink: "/admin/customers"
    },

    {
        id: "admin-campaign",
        title: "کمپین ",
        type: "collapse",
        icon: <TbBrandCampaignmonitor size={20}/>,
        permissions: ["admin"],
        children:[
            {
                id: "admin-marride-campaign",
                title: "کمپین ازدواج",
                type: "item",
                icon: <GiDiamondRing size={20}/>,
                navLink: '/admin/marriage-campaign',
                permissions: ["admin"]
            },
            {
                id: "admin-birthday-campaign",
                title: "کمپین تولد",
                type: "item",
                icon: <FaBirthdayCake size={20}/>,
                navLink: '/admin/birthday-campaign',
                permissions: ["admin"]
            }
        ]

    },
    {
        id: "web_management",
        title: "WebManagement",
        type: "collapse",
        icon: <Icon.Globe size={20}/>,
        permissions: ["admin"],
        children: [
            {
                id: "web_setting",
                title: "Setting",
                type: "item",
                icon: <Icon.Settings size={18}/>,
                permissions: ["admin"],
                navLink: "/admin/web_setting"
            },
        ]

    },
    {
        id: "admin_discounts",
        title: "امتیاز بندی بازه",
        type: "item",
        icon: <Icon.Archive size={20}/>,
        navLink: '/admin/grades-price',
        permissions: ["admin"]
    },
    {
        id: "admin_Available",
        title: "FormAvailablePanel",
        type: "item",
        icon:<CgUnavailable size={20} />,
        navLink: '/admin/available',
        permissions: ["admin"]
    },
    {
        id: "admin_orders",
        title: "Orders",
        type: "item",
        icon: <Icon.File size={20}/>,
        navLink: '/admin/orders',
        permissions: ["admin"]
    },
    {
        id: "admin_invoices",
        title: "Invoices",
        type: "item",
        icon: <Icon.Clipboard size={20}/>,
        navLink: '/admin/invoices',
        permissions: ["admin"]
    },

        {
            id: "system_categories",
            title: "ProductsCategories",
            type: "item",
            icon: <Icon.List size={15}/>,
            navLink: '/admin/categories',
            permissions: ["admin"]
        },
    {
        id: "admin_products",
        title: "Products",
        type: "item",
        icon: <Icon.Archive size={20}/>,
        navLink: '/admin/products',
        permissions: ["admin"]
    },
    {
        id: "admin_prices",
        title: "Prices",
        type: "item",
        icon: <MdOutlinePriceChange size={20}/>,
        navLink: '/admin/prices',
        permissions: ["admin"]
    },
    {
        id: "admin_category_article",
        title: "ArticleCategory",
        type: "item",
        icon: <Icon.List size={20}/>,
        navLink: '/admin/article-category',
        permissions: ["admin"]
    },
    {
        id: "admin_article",
        title: "Article",
        type: "item",
        icon: <Icon.File size={20}/>,
        navLink: '/admin/article',
        permissions: ["admin"]
    },
    {
        id: "admin_coupons",
        title: "Coupons",
        type: "item",
        icon: <Icon.Percent size={20}/>,
        navLink: '/admin/coupons',
        permissions: ["admin"]
    },
    {
        id: "admin_Brand",
        title: "Brands",
        type: "item",
        icon: <TbBrandAsana size={20} />,
        navLink: '/admin/brands',
        permissions: ["admin"]
    },
    {
        id: "admin_sendGoods",
        title: "HowToSendGoods",
        type: "item",
        icon: <Icon.Truck size={20}/>,
        navLink: '/admin/send-goods',
        permissions: ["admin"]
    },
    {
        id: "admin_comments",
        title: "AcceptComments",
        type: "item",
        icon: <Icon.MessageSquare size={20}/>,
        navLink: '/admin/comments',
        permissions: ["admin"]
    },

    {
        id: "admin_gallery",
        title: "Gallery",
        type: "item",
        icon: <Icon.Image size={20}/>,
        navLink: '/admin/gallery',
        permissions: ["admin"]
    },
    {
        id: "admin_stories",
        title: "Stories",
        type: "item",
        icon: <Icon.PlayCircle size={20}/>,
        navLink: '/admin/stories',
        permissions: ["admin"]
    },
    {
        id: "admin_ContactForm",
        title: "FormContactPanel",
        type: "item",
        icon: <Icon.Mail size={20}/>,
        navLink: '/admin/contactForm',
        permissions: ["admin"]
    },

    // SELLER NAVIGATION
    {
        id: "seller_dashboard",
        title: "Dashboard",
        type: "item",
        icon: <Icon.Home size={20}/>,
        navLink: '/seller/dashboard',
        permissions: ["seller"]
    },
    {
        id: "seller_products",
        title: "Products",
        type: "item",
        icon: <Icon.Circle size={15}/>,
        navLink: '/seller/products',
        permissions: ["seller"]
    },
    {
        id: "seller_orders",
        title: "Orders",
        type: "item",
        icon: <Icon.File size={20}/>,
        navLink: '/seller/orders',
        permissions: ["seller"]
    },
    // CUSTOMER NAVIGATION
    {
        id: "user_dashboard",
        title: "Dashboard",
        type: "item",
        icon: <Icon.Home size={20}/>,
        navLink: '/customer/dashboard',
        permissions: ["customer"]
    },
    {
        id: "user_interest",
        title: "Interest",
        type: "item",
        icon: <Icon.Heart size={20}/>,
        navLink: '/customer/interest',
        permissions: ["customer"]
    },
    {
        id: "user_orders",
        title: "Orders",
        type: "item",
        icon: <Icon.File size={20}/>,
        navLink: '/customer/orders',
        permissions: ["customer"]
    },
    {
        id: "user_comments",
        title: "Comments",
        type: "item",
        icon: <Icon.MessageSquare size={20}/>,
        navLink: '/customer/comments',
        permissions: ["customer"]
    },
    {
        id: "user_address",
        title: "Address",
        type: "item",
        icon: <Icon.Compass size={20}/>,
        navLink: '/customer/address',
        permissions: ["customer"]
    },
    {
        id: "user_invoices",
        title: "Invoices",
        type: "item",
        icon: <Icon.Clipboard size={20}/>,
        navLink: '/customer/invoices',
        permissions: ["customer"]
    },
    {
        id: "user_available",
        title: "FormAvailablePanel",
        type: "item",
        icon: <CgUnavailable size={20} />,
        navLink: '/customer/customer-available',
        permissions: ["customer"]
    },
    {
        id: "user_profile",
        title: "Profile",
        type: "item",
        icon: <Icon.User size={20}/>,
        navLink: '/customer/profile',
        permissions: ["customer"]
    },


    // {
    //     id: "admin_FaqForm",
    //     title: "FormFaqPanel",
    //     type: "item",
    //     icon: <MdQuestionMark size={20}/>,
    //     navLink: '/admin/faq',
    //     permissions: ["admin"]
    // },

    // system navigation

    {
        id: "system_products",
        title: "Products",
        type: "item",
        icon: <Icon.Archive size={20}/>,
        navLink: '/system/products',
        permissions: ["system"]
    }, {
        id: "system_category_article",
        title: "ArticleCategory",
        type: "item",
        icon: <Icon.List size={20}/>,
        navLink: '/system/article-category',
        permissions: ["system"]
    },
    {
        id: "system_article",
        title: "Article",
        type: "item",
        icon: <Icon.File size={20}/>,
        navLink: '/system/article',
        permissions: ["system"]
    },
    {
        id: "system_categories",
        title: "Categories",
        type: "item",
        icon: <Icon.Circle size={15}/>,
        navLink: '/system/categories',
        permissions: ["system"]
    },
    {
        id: "system_sections",
        title: "Sections",
        type: "item",
        icon: <Icon.Circle size={15}/>,
        navLink: '/system/sections',
        permissions: ["system"]
    },
    {
        id: "system_menu",
        title: "Menu",
        type: "item",
        icon: <Icon.Circle size={15}/>,
        navLink: '/system/menu',
        permissions: ["system"]
    },
    {
        id: "system_slider",
        title: "Slider",
        type: "item",
        icon: <Icon.Circle size={15}/>,
        navLink: '/system/slider',
        permissions: ["system"]
    },
    {
        id: "system_gallery",
        title: "Gallery",
        type: "item",
        icon: <Icon.Image size={20}/>,
        navLink: '/system/gallery',
        permissions: ["system"]
    },
    {
        id: "system_stories",
        title: "Stories",
        type: "item",
        icon: <Icon.PlayCircle size={20}/>,
        navLink: '/system/stories',
        permissions: ["system"]
    },
]

export default navigationConfig
