/**
 * Converts an object to a URL-encoded query string.
 *
 * @param {string} url - The base URL to use for the query string.
 * @param {object} data - The object to convert to a query string.
 * @param {string} [prefix=""] - Optional prefix to use for each parameter name.
 * @returns {URLSearchParams} The resulting URL-encoded query string.
 */

const createQueryString = (data, prefix) => {
    let queryParams = "";

    for (const [key, value] of Object.entries(data)) {
        const finalKey = prefix ? `${prefix}[${key}]` : key;
        if (value && typeof value === "object") {
            queryParams += createQueryString(value, finalKey); // Recursive call to handle nested objects
        } else {
            queryParams += `&${finalKey}=${encodeURI(value)}`;
        }
    }

    return queryParams
}


/**
 Converts an object into a URL query string and appends it to the given URL.
 @param {string} url - The base URL.
 @param {object} data - The data object to be converted into a query string.
 @param {string} [prefix=""] - Optional prefix for the query string keys.
 @returns {string} - The modified URL with the appended query string. */
const ObjectToURL = (url, data, prefix = "") => {
    if (!data) return url;
    const queryString = createQueryString(data, prefix).toString();
    if (!queryString) return url;

    // بررسی آیا پارامتری در آدرس URL وجود دارد یا نه
    const separator = url.includes("?") ? "&" : "?";

    // اضافه کردن رشته پرس و جو به آدرس URL
    const finalQueryString = queryString.replace("&", separator);
    return `${url}${finalQueryString}`;
};


export default ObjectToURL;












